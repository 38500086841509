.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.text-container {
  margin-top: 40px;
}

.link-separator {
  margin: 0 8px;
}

p a {
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
}

p a:hover {
  color: #0056b3;
  text-decoration: underline;
}

p {
  line-height: 1.6;
}

p a {
  margin-right: -0.2em;
}

#textContent {
  width: 40%;
  float: left;
  height: 100%;
  padding: 40px;
  box-sizing: border-box; /* ensures padding is included in the width */
  overflow-y: auto; /* Adds vertical scroll */
  max-height: calc(100vh - 80px); /* Adjusts the max height to fit the screen */
}

@media only screen and (max-width: 768px) {
  /* Adjust the main container to stack children vertically */
  .main-container {
    flex-direction: column;
  }

  #canvasContainer,
  #textContent {
    width: 100%;
    max-height: 45vh;
    overflow-y: auto; /* Enable scrolling */
  }

  #textContent {
    padding: 20px;
  }
}
