@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior: none;
  background: #151515;
  color: #a0a0a0;
  font-family: 'Inter var', sans-serif;
}

h1 {
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
}

a {
  pointer-events: all;
  color: #a0a0a0;
  text-decoration: none;
}

a svg {
  fill: currentColor;
}

#canvasContainer {
  width: 60%;
  float: left;
  overflow: hidden; /* this ensures the canvas does not spill over its container */
  height: 100vh;
  display: flex;
  align-items: center; /* vertically center the canvas */
  justify-content: center; /* horizontally center the canvas */
}

#textContent {
  width: 40%;
  float: left;
  height: 100%;
  padding: 40px;
  box-sizing: border-box; /* ensures padding is included in the width */
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 0; /* Aligns the footer to the right side */
  left: 60%;
  padding-right: 10px; /* Adds some padding on the right */
}

.icon {
  margin: 0 50px; /* Increase space between icons */
}

.icon img {
  width: 22px;
  transition: transform 0.3s ease; /* Adds a smooth transition on hover */
}

.icon:hover img {
  transform: scale(1.1); /* Slightly enlarges the icon on hover */
}

@media only screen and (max-width: 768px) {
  #canvasContainer,
  #textContent {
    width: 100%;
    float: none;
    display: block; /* Stacked vertically */
    overflow-y: auto; /* Allows scrolling if content overflows */
  }

  #textContent {
    padding: 20px;
    margin-bottom: 50px; /* Manage space above the footer*/
  }

  .footer {
    position: fixed;
    bottom: 0; /* Aligns footer at the bottom */
    left: 0;
    width: 100%;
    padding: 10px 0; /* Adjust padding for visual comfort */
  }

  .icon {
    margin: 5px 25px; /* Vertical spacing for mobile */
  }

  .icon img {
    width: 20px;
  }
}
